// nav.js
import logo from './img/logo.png';
import mintify from './img/mintify.png';
import x from './img/x.png';
import discord from './img/discord.png';

function NavBar() {
  return (
    <nav className="px-4 pt-1">
      <div className="container-fluid pb-4" style={{ marginTop: '8px !important' }}>
        <div className="row align-items-center" style={{ minHeight: '96px' }}>
          {/* Left Section */}
          <div className="col-6 d-flex align-items-center justify-content-start">
            <img className="img-fluid" src={logo} style={{ height: '96px' }} alt="logo" />
          </div>

          {/* Right Section */}
          <div className="col-6 d-flex align-items-center justify-content-end pt-1">
            <a href="https://ordinals.mintify.xyz/" target="_blank" rel="noreferrer">
              <img className="img-fluid" src={mintify} style={{ height: '32px', marginRight: '16px' }} alt="magiceden" />
            </a>
            <a href="https://x.com/btccorpses" target="_blank" rel="noreferrer">
              <img className="img-fluid" src={x} style={{ height: '32px', marginRight: '16px' }} alt="etherscan" />
            </a>
           {/* <a href="#" target="" rel="noreferrer">
              <img className="img-fluid" src={discord} style={{ height: '32px' }} alt="discord" />
            </a> */}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
