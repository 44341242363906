// src/MintPage.js

import React, { useState } from 'react';
import './App.css'; // Import your main CSS file if needed
import mintImage from './img/mint.png'; // Import the image from src folder

function MintPage() {
  // Base price for a single ordinal
  const basePrice = 0.00033333;

  // State to track the counter value (1-5)
  const [count, setCount] = useState(1);

  // State to track the receive address
  const [receiveAddress, setReceiveAddress] = useState('');

  // Function to handle increment and decrement of the counter
  const handleCounterChange = (value) => {
    if (value >= 1 && value <= 5) {
      setCount(value);
    }
  };

  // Calculate the total price based on the count
  const totalPrice = (count * basePrice).toFixed(8); // Display 8 decimal places

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '8px' }}>

      {/*<div style={{ marginBottom: '20px', fontSize: '24px', color: '#fff', textAlign: 'center' }}>
        <button
          onClick={() => handleCounterChange(count - 1)}
          disabled={count === 1}
          style={{
            padding: '10px',
            marginRight: '10px',
            fontSize: '1.5em',
            cursor: 'pointer',
            backgroundColor: '#000',
            color: '#fff',
            border: 'none',
          }}
        >
          -
        </button>
        <span style={{ margin: '0 15px' }}>{count}</span>
        <button
          onClick={() => handleCounterChange(count + 1)}
          disabled={count === 5}
          style={{
            padding: '10px',
            marginLeft: '10px',
            fontSize: '1.5em',
            cursor: 'pointer',
            backgroundColor: '#000',
            color: '#fff',
            border: 'none',
          }}
        >
          +
        </button>
        <p style={{ fontSize: '0.5em', margin: '-4px' }}>5 MAX PER TX</p>
        <p style={{ fontSize: '1em' }}>{totalPrice} BTC</p>
      </div>

      <div style={{ marginBottom: '24px', marginTop: '-24px', textAlign: 'center', color: '#fff' }}>
        <label htmlFor="receiveAddress" style={{ fontSize: '1em', marginRight: '10px' }}>
          ORDINAL RECEIVE ADDRESS:
        </label>
        <br></br>
        <input
          type="text"
          id="receiveAddress"
          value={receiveAddress}
          onChange={(e) => setReceiveAddress(e.target.value)}
          placeholder="ENTER ORDINAL RECEIVE ADDRESS"
          style={{
            width: '300px',
            border: '1px solid #fff',
            borderRadius: '4px',
            backgroundColor: '#000',
            color: '#fff',
          }}
        />
      </div>*/}

<a
  href="#"
  style={{
    display: 'inline-block',
    marginTop: '16px',
    padding: '8px 64px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    outline: 'none',
    textDecoration: 'none',
    textAlign: 'center',
  }}
  onClick={(e) => {
    e.preventDefault();
    // Perform action when clicking mint, e.g., send the receive address and count
    if (receiveAddress === '') {
      // Optionally, add some other form of feedback here if needed
      console.log('Please enter a receive address!'); // Logs to console instead of showing an alert
    } else {
      // Perform your desired minting action here without any alerts
      console.log(`Minting ${count} item(s) to address: ${receiveAddress}`);
    }
  }}
>

        <img src={mintImage} alt="Mint" style={{ width: '100%', height: '100%' }} />
      </a>

      {/* Additional Information */}
      <p className="p-1">COMING SOON...</p>
      <h1 style={{ marginTop: '-16px'}}>0/3333</h1>
    </div>
  );
}

export default MintPage;
