import React from 'react';
import './index.css';
import FooterLogo from './img/logo_big.png'; 

function App() {
  return (



      <div className="container-fluid splash">
        <div className="row justify-content-center">
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 text-center align-items-center py-4">
            <img className="img-fluid" src={FooterLogo} alt="BitcoinCorpses" style={{ width: '256px', height: '256px' }} />
            <p>FOR PUPPETS BY PUPPETS.</p> 
          </div>
        </div>
      </div>



  );
}

export default App;