// src/Gallery.js
import React, { useEffect, useState, useRef } from 'react';
import './gallery.css'; // Import the CSS file for styling
import CollectionHeader from './img/collection.png'; 

function Gallery() {
  // State to track all images that have been loaded so far
  const [loadedImages, setLoadedImages] = useState([]);
  const [nextBatch, setNextBatch] = useState(0);

  // Number of images to load per batch (for progressive loading)
  const IMAGES_PER_BATCH = 50;

  // Total number of images in your directory
  const TOTAL_IMAGES = 300;

  // Set the directory path to your images here
  const imageDirectory = '/images/';

  // Function to format the number with leading zeros
  const formatNumberWithLeadingZeros = (number, totalDigits) => {
    return number.toString().padStart(totalDigits, '0');
  };

  // Create an array of image paths based on the formatted number and total images
  let imagePaths = Array.from({ length: TOTAL_IMAGES }, (_, index) => {
    // Format index with 4 leading zeros (e.g., 0001, 0002, ...)
    const formattedNumber = formatNumberWithLeadingZeros(index + 1, 4);
    return `${imageDirectory}${formattedNumber}.png`;
  });

  // Function to shuffle the image paths array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Shuffle the imagePaths array
  imagePaths = shuffleArray(imagePaths);

  // Function to load the next batch of images
  const loadNextBatch = () => {
    const newBatch = imagePaths.slice(nextBatch, nextBatch + IMAGES_PER_BATCH);
    setLoadedImages((prevImages) => [...prevImages, ...newBatch]);
    setNextBatch(nextBatch + IMAGES_PER_BATCH);
  };

  // Reference for the observer to track when the last image is visible
  const observerRef = useRef();

  // useEffect to set up the Intersection Observer for infinite scrolling
  useEffect(() => {
    loadNextBatch(); // Load the initial batch of images when the component mounts

    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting && nextBatch < TOTAL_IMAGES) {
          loadNextBatch(); // Load next batch of images when the last image is visible
        }
      },
      {
        root: null, // Use the browser viewport as the container
        rootMargin: '200px', // Trigger when 200px before reaching the bottom
        threshold: 1.0, // Fully visible
      }
    );

    // Observe the last image in the grid
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [nextBatch, TOTAL_IMAGES]); // Re-run the effect if nextBatch or TOTAL_IMAGES changes

  return (
    <div style={{ textAlign: 'center' }}>
    <img className="img-fluid mb-4" src={CollectionHeader} alt="CollectionHeader" />
      {/* Display images in a grid */}
      <div className="gallery-grid">
        {loadedImages.map((imagePath, index) => (
          <div
            key={index}
            className="image-container"
            ref={index === loadedImages.length - 1 ? observerRef : null} // Attach ref to the last image
          >
            <img
              src={imagePath}
              alt={`Gallery Image ${index + 1}`}
              loading="lazy" // Use lazy loading for better performance
              className="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gallery;