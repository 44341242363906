import React from 'react';
import './index.css';
import splash from './img/logo_big.png'; 

function App() {
  return (



      <div className="container-fluid splash">
        <div className="row justify-content-center">
          <div className="col-xs-10 col-sm-10 col-md-10 col-lg-8 col-xl-8 text-center align-items-center">
            <img className="img-fluid" src={splash} alt="BitcoinCorpses" />
          </div>
        </div>
      </div>



  );
}

export default App;