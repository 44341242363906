// App.js
import React from 'react';
import './App.css';
import NavBar from './nav';
import Splash from './splash';
import Mint from './mint';
import Manifesto from './manifesto.js'
import Roadmap from './roadmap.js'
import Gallery from './gallery.js'
import Footer from './footer.js'


function App() {
  return (
    <div className="App">
      <NavBar />
      <Splash />
      <Mint />
      <Manifesto />
      <Roadmap />
      <Gallery />
      <Footer />
    </div>
  );
}

export default App;
